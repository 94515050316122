// Commom
.pointer {
  cursor: pointer;
}
.text-primary {
  color: #FF7100 !important;
}
.text-bold {
  font-weight: bold;
}

// Sidebar
.c-sidebar {
  background: #FF7100;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #ef8907;
}

// Icons
.icon-img {
  width: 50px;
  height: 50px;
  margin: 10px 0px;
}
.icon-login {
  width: 150px;
  height: 150px;
}


// Backgrounds
.bg-primary {
  background-color: #FF7100 !important;
}


// Buttons
.btn-primary {
  color: #fff;
  background-color: #FF7100 !important;
  border-color: #FF7100 !important;
}

.btn-primary:not(.disabled):hover, .btn-primary:not(.disabled):focus, .btn-primary:not(.disabled):active, .btn-primary:not(.disabled):visited {
  color: #fff;
  background-color: #cc4016 !important;
  border-color: #cc4016 !important;
}

.bg-secondary {
  background-color: #ef8907 !important;
}

.btn-secondary {
  color: #fff;
  background-color: #ef8907 !important;
  border-color: #ef8907 !important;
}

.btn-secondary:not(.disabled):hover, .btn-secondary:not(.disabled):focus, .btn-secondary:not(.disabled):active, .btn-secondary:not(.disabled):visited {
  color: #fff;
  background-color: #F7C456 !important;
  border-color: #F7C456 !important;
}


// Pagination
.page-item.active .page-link {
  background-color: #FF7100;
  border-color: #FF7100;
}
.page-link {
  color: #FF7100;
}
.page-link:hover {
  color: #FF7100;
  background-color: #d8dbe0;
  border-color: #c4c9d0;
}

// Cards
.card-title {
  margin-bottom: 0 !important;
}